import React from 'react';
import { graphql } from 'gatsby';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import { SanityImage } from '@components/sanity/sanityImage';
import SimplePortableText from '@components/sanity/simplePortableText';
import CardCarousel from '@components/pageModules/modules/cardCarousel';
import { useJobListingQuery } from '../GraphQl/useJobListingQuery';
import PortableLink from '@components/UI/portableLink';
import { Link as ScrollLink } from 'react-scroll';

const JobListingPageTemplate = ({ data: { page } }) => {
  const { seo, title, listingIntro, listingImage, details } = page || {};
  const { nodes } = useJobListingQuery();

  const filteredCareers = nodes?.filter((node) => node?.title !== title);

  return (
    <Layout reverseHeader={true}>
      <PageMeta {...seo} />
      <div className=' pt-36 xl:pt-96  bg-light-blue text-grey px-gutter'>
        <div className='text-center pb-space-m md:px-gutter'>
          <h1 className='richH0 mb-7'>{title}</h1>
          <p className='richH2'>{listingIntro}</p>
          <ScrollLink
            to={'description'}
            offset={0}
            smooth={true}
            className='flex justify-center w-fit mx-auto mt-7'>
            <PortableLink linkText='Tell me more' />
          </ScrollLink>
        </div>
        <div className='aspect-w-5 aspect-h-3 relative  '>
          <SanityImage
            image={listingImage}
            className='absolute top-0 left-0 w-full h-full object-cover'
          />
        </div>
        <div
          className='flex flex-col-reverse lg:grid grid-cols-12 pt-space-m '
          id='description'>
          <div className='col-start-6 col-end-13 row-start-1 row-end-3 mt-14 lg:mt-0'>
            <SimplePortableText text={details?.description} />
          </div>
          <div className='col-start-1 col-end-5 row-start-1 row-end-2 '>
            <h1 className='richH5 text-blue mb-6'>Requirements:</h1>
            <SimplePortableText text={details?.requirements} />
          </div>
          <div className='col-start-1 col-end-5 row-start-2 row-end-3 lg:mt-10'>
            <h1 className='richH5 text-blue mb-6'>Offers:</h1>
            <SimplePortableText text={details?.offers} />
          </div>
        </div>
      </div>
      <div className='bg-light-blue pt-space-m pb-space-m'>
        <CardCarousel
          items={filteredCareers}
          heading='Other Vacancies'
          path={'careers'}
        />
      </div>
    </Layout>
  );
};

export default JobListingPageTemplate;

export const pageQuery = graphql`
  query standardJobListingPageQuery($slug: String!) {
    page: sanityJobListing(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      listingIntro
      location
      title
      listingImage {
        ...ImageWithPreview
      }
      details {
        description: _rawDescription(resolveReferences: { maxDepth: 10 })
        offers: _rawOffers(resolveReferences: { maxDepth: 10 })
        requirements: _rawRequirements(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`;
